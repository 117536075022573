<template>
  <div class="p-3 pt-0">
    <router-view></router-view>
  </div>
</template>

<script>

// export default {
//   setup() {
//   },
// };
</script>

<style scoped>
</style>